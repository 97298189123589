@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: var(--font-volvo-novum);
}

body {
  color: "black";
  background: "white"
}

*, *:before, *:after {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type="text"] {
  border-radius: 0 !important;
  -webkit-appearance: none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
